body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "OliviaBloomRegular";
  src: local("OliviaBloomRegular"),
  url("./assets/fonts/Olivia Bloom Regular.otf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Junicode";
  src: local("Junicode"),
  url("./assets/fonts/Junicode.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "JunicodeBold";
  src: local("JunicodeBold"),
  url("./assets/fonts/Junicode-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "JunicodeBoldItalic";
  src: local("JunicodeBoldItalic"),
  url("./assets/fonts/Junicode-BoldItalic.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "JunicodeItalic";
  src: local("JunicodeItalic"),
  url("./assets/fonts/Junicode-Italic.ttf") format("truetype");
  font-weight: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.dndActive {
  background-color: rgba(214, 174, 80, 0.3);
  box-shadow: 0 0 12px rgba(214, 174, 80, 1);
  transition: 0.4s;
}
.dnd:hover {
  background-color: rgba(214, 174, 80, 0.3);
  box-shadow: 0 0 12px rgba(214, 174, 80, 1);
  transition: 0.4s;
}